import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  useRef,
  useState,
} from 'react'
import cn from 'classnames'
import { keys } from 'common/utils/keys'

export const sizeClassNames = {
  default: '',
  large: 'form-control-lg',
  small: 'form-control-sm',
}

export type InputProps = {
  textarea?: boolean
  isValid?: boolean
  placeholder?: string
  inputClassName?: string
  name?: string
  icon?: string
  readOnly?: boolean
  id?: string
  type?: string
  textButton?: string
  className?: string
  iconColour?: string
  touched?: boolean
  value?: string
  onIconClick?: () => void
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  disabled?: boolean
  'data-test'?: string
  size?: keyof typeof sizeClassNames
}

const Input: FC<InputProps> = ({
  children,
  className,
  disabled,
  icon,
  iconColour,
  id,
  inputClassName,
  isValid = true,
  name,
  onBlur,
  onFocus,
  onIconClick,
  onKeyDown,
  placeholder = ' ',
  readOnly,
  size = 'default',
  textarea,
  touched,
  type,
  value,
  ...rest
}) => {
  const [shouldValidate, setShouldValidate] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement>()
  const focusHandler: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setIsFocused(true)
    onFocus && onFocus(e)
  }

  // Is it element important? Should I use UseRef hook?
  // const focus = () => {
  //   this.input.focus();
  // };

  const _onKeyDown: KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    if (keys.isEscape(e)) {
      ref.current?.blur()
    }
    onKeyDown && onKeyDown(e)
  }

  const blur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e,
  ) => {
    setShouldValidate(true)
    setIsFocused(false)
    onBlur && onBlur(e)
  }

  const classNameHandler = cn(
    // 'position-relative',
    {
      focused: isFocused,
      invalid: (shouldValidate || touched) && !isValid,
    },
    className,
  )

  const combinedInputClassName = cn(
    { error: !(shouldValidate || touched) && !isValid, 'form-control': true },
    sizeClassNames[size],
    inputClassName,
  )
  const [showPassword, setShowPassword] = useState<boolean>(false)
  return (
    <div className={classNameHandler}>
      {textarea ? (
        <>
          <textarea
            disabled={disabled}
            name={name}
            placeholder={placeholder}
            {...rest}
            id={id}
            // @ts-ignore
            ref={ref}
            onFocus={focusHandler}
            onKeyDown={_onKeyDown}
            onBlur={blur}
            readOnly={readOnly}
            value={value}
            className={combinedInputClassName}
          />
        </>
      ) : (
        <>
          <input
            disabled={disabled}
            name={name}
            type={type === 'password' && showPassword ? '' : type}
            {...rest}
            id={id}
            // @ts-ignore
            ref={ref}
            onFocus={focusHandler}
            onKeyDown={_onKeyDown}
            onBlur={blur}
            readOnly={readOnly}
            value={value}
            placeholder={placeholder}
            className={combinedInputClassName}
          />
          {icon ? (
            <span
              onClick={
                onIconClick
                  ? onIconClick
                  : () => {
                      ref.current?.focus()
                    }
              }
              style={{
                bottom: 12,
                color: iconColour,
                cursor: 'pointer',
                position: 'absolute',
                right: 10,
              }}
            >
              {icon}
            </span>
          ) : (
            type == 'password' && (
              <i
                data-test={`${rest['data-test']}-icon`}
                onClick={() => {
                  if (type === 'password') {
                    setShowPassword(!showPassword)
                  } else {
                    onIconClick ? onIconClick() : ref?.current?.focus()
                  }
                }}
                className={cn(
                  'text-primary',
                  {
                    'fas fa-eye': type === 'password' && !showPassword,
                    'fas fa-eye-slash': type === 'password' && showPassword,
                    icon: true,
                  },
                  icon,
                )}
                style={{
                  bottom: 10,
                  color: iconColour,
                  cursor: 'pointer',
                  position: 'absolute',
                  right: 10,
                }}
              />
            )
          )}
        </>
      )}
      {children && children}
    </div>
  )
}

Input.displayName = 'Input'
export default Input
