import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import React, { FC, ReactNode } from 'react'
import { Project } from 'common/project'
import Card, { CardType } from './base/Card'
import Icon from './Icon'

interface ModalDefault {
  title?: ReactNode
  isOpen: boolean
  onDismiss: () => void
  toggle: () => void
  padding?: CardType['padding']
  contentClassName?: string
  className?: string
  centered?: boolean
}
export const CloseIcon = ({ ...rest }) => {
  return (
    <Icon
      {...rest}
      className='cursor-pointer'
      width={16}
      height={16}
      name='close'
    />
  )
}
export const ModalTitle = ({
  onDismiss,
  title,
}: {
  title: ReactNode
  onDismiss: () => void
}) => {
  return (
    <div className='d-flex flex-row justify-content-between pb-3'>
      <h1 className='mb-0 pt-5 text-semibold'>{title}</h1>
      <CloseIcon className='cursor-pointer close-btn' onClick={onDismiss} />
    </div>
  )
}
const ModalDefault: FC<ModalDefault> = ({
  centered,
  children,
  className,
  contentClassName,
  isOpen,
  onDismiss,
  padding,
  title,
  toggle,
}) => {
  const onDismissClick = () => {
    if (onDismiss) {
      onDismiss()
    }
    toggle()
  }
  return (
    <Modal
      className={className}
      unmountOnClose
      isOpen={isOpen}
      toggle={onDismissClick}
      centered={centered}
    >
      {!!title && <ModalTitle onDismiss={onDismiss} title={title} />}
      <Card padding={padding} className={contentClassName}>
        {children}
      </Card>
    </Modal>
  )
}

export default ModalDefault
